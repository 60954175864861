import "./App.css";
import "graphiql/graphiql.min.css";
import { useState, useCallback, useEffect, useMemo, useReducer } from "react";
import {
  Page,
  LegacyCard,
  Button,
  TextField,
  Select,
  Checkbox,
  LegacyStack,
  FooterHelp,
  Link,
  FormLayout,
} from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { decodeJwt } from "jose";
import ReactJson from "react-json-view";
import { GraphiQL } from "graphiql";

import { useCreateToast } from "./OverlayManager";

const ENDPOINT_BASE = process.env.REACT_APP_ENDPOINT_BASE;

const API_VERSION = "2024-01"; // update MINIMUM_API_VERSION in backend/index.js

function TokenMagic({ setToken }) {
  const [localToken, setLocalToken] = useState(null);
  const [needsToFetchToken, setNeedsToFetchToken] = useState(false);

  // check for an existing token
  useEffect(() => {
    if (localToken == null) {
      try {
        const googleSignInToken = localStorage.getItem("googleSignInToken");
        if (!googleSignInToken) {
          setNeedsToFetchToken(true);
          console.log("cant find token");
          return;
        } else {
          const { exp } = decodeJwt(googleSignInToken);
          if (new Date() / 1000 + 30 > exp) {
            setNeedsToFetchToken(true);
            console.log("token expired");
            return;
          }
          console.log(
            `using token that is still good for ${
              exp - new Date() / 1000
            } seconds`
          );
          setLocalToken(googleSignInToken);
        }
      } catch (ex) {
        setNeedsToFetchToken(true);
        console.log("bonk", ex);
      }
    } else {
      try {
        localStorage.setItem("googleSignInToken", localToken);
        setToken(localToken);
      } catch (ex) {}
    }
  }, [localToken, setToken]);

  // remove expired tokens
  useEffect(() => {
    let hasRun = false;
    console.log("localToken", localToken);
    if (localToken) {
      try {
        const { exp } = decodeJwt(localToken);
        const delay = Math.max(1000, (exp - 30) * 1000 - new Date());
        console.log(`setting timeout that nulls out localToken in ${delay}ms`);
        const timeout = setTimeout(() => {
          hasRun = true;
          console.log("token has expired, clearing out localToken");
          setLocalToken(null);
          setNeedsToFetchToken(true);
        }, delay);
        return () => {
          if (!hasRun) {
            console.log("clearing timeout that nulls out localToken");
          }
          clearTimeout(timeout);
        };
      } catch (ex) {
        console.log("expired jwt timer setup", ex);
      }
    }
    return () => {};
  }, [localToken]);

  // try to get a new token
  useEffect(() => {
    if (needsToFetchToken) {
      const script = document.createElement("script");
      const div = document.createElement("div");
      script.async = true;
      script.src = "https://accounts.google.com/gsi/client";

      [
        ["id", "g_id_onload"],
        [
          "data-client_id",
          "773248862236-p6hj23g1m32tmam0v9b7kts0vq5cr73a.apps.googleusercontent.com",
        ],
        ["data-context", "signin"],
        ["data-ux_mode", "popup"],
        ["data-callback", "googleSignInCallback"],
        ["data-auto_prompt", "true"],
        ["data-auto_select", "true"],
        ["data-itp_support", "true"],
        ["data-use_fedcm_for_prompt", "true"],
      ].forEach(([key, value]) => div.setAttribute(key, value));

      document.head.appendChild(script);
      document.body.appendChild(div);
      window.googleSignInCallback = ({ credential }) => {
        setNeedsToFetchToken(false);
        setLocalToken(credential);
      };
      return () => {
        script.remove();
        div.remove();
      };
    } else return () => {};
  }, [setToken, needsToFetchToken]);

  return needsToFetchToken ? (
    <div
      className="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-text="signin"
      data-size="large"
      data-logo_alignment="left"
    ></div>
  ) : null;
}

async function getAppLoginUrl(token, app, hostname, reason) {
  const url = new URL(`${ENDPOINT_BASE}/store_login`, window.location);
  url.searchParams.append("app", app);
  url.searchParams.append("hostname", hostname);
  url.searchParams.append("reason", reason);

  const response = await fetch(url, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: "",
  });
  const { redirect } = await response.json();
  return redirect;
}

const INITIAL_STATE = {
  apps: null,
  token: null,
  debugText: "",
  app: "",
  inputHostname: "",
  reason: "",
  locked: false,
  sid: null,
  discountCode: null,
  scopes: "",
  generatedUrl: "",
  contents: "",
  codeSignature: "",
  planId: "1",
  percentOff: "0",
  trialDays: "0",
  expiryDate: new Date(+new Date() + 7 * 24 * 3600 * 1000)
    .toISOString()
    .replace(/T.*/, ""),
  totalUses: "1",
  chargeName: "",
  chargePrice: "0",
  chargeIsTest: true,
  chargeConfirmationUrl: "",
};

function PrettyOrText({ value }) {
  const parsedObject = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }, [value]);
  return parsedObject ? <ReactJson src={parsedObject} /> : <pre>{value}</pre>;
}

function App() {
  const createToast = useCreateToast();
  const [data, dispatch] = useReducer((last, [key, value]) => {
    const next = { ...last, [key]: value };

    if (key === "app" || key === "inputHostname" || key === "reason") {
      next.sid = "";
      next.discountCode = "";
      next.generatedUrl = "";
      next.codeSignature = "";
    }

    if (
      key === "planId" ||
      key === "percentOff" ||
      key === "trialDays" ||
      key === "expiryDate" ||
      key === "totalUses"
    ) {
      next.discountCode = "";
    }

    if (key === "scopes") {
      next.generatedUrl = "";
    }

    if (key === "contents") {
      next.codeSignature = "";
    }

    return next;
  }, INITIAL_STATE);

  const {
    apps,
    token,
    debugPath,
    debugText,
    iGotThis,
    app,
    inputHostname,
    reason,
    locked,
    sid,
    discountCode,
    scopes,
    generatedUrl,
    contents,
    codeSignature,
    planId,
    percentOff,
    trialDays,
    expiryDate,
    totalUses,
    chargeName,
    chargePrice,
    chargeIsTest,
    chargeConfirmationUrl,
  } = data;

  const setApps = useCallback((value) => dispatch(["apps", value]), []);
  const setToken = useCallback((value) => dispatch(["token", value]), []);
  const setDebugText = useCallback(
    (value) => dispatch(["debugText", value]),
    []
  );
  const setDebugPath = useCallback(
    (value) => dispatch(["debugPath", value]),
    []
  );
  const setIGotThis = useCallback((value) => dispatch(["iGotThis", value]), []);
  const setApp = useCallback((value) => dispatch(["app", value]), []);
  const setInputHostname = useCallback(
    (value) => dispatch(["inputHostname", value]),
    []
  );
  const setReason = useCallback((value) => dispatch(["reason", value]), []);
  const setLocked = useCallback((value) => dispatch(["locked", value]), []);
  const setSid = useCallback((value) => dispatch(["sid", value]), []);
  const setDiscountCode = useCallback(
    (value) => dispatch(["discountCode", value]),
    []
  );
  const setScopes = useCallback((value) => dispatch(["scopes", value]), []);
  const setGeneratedUrl = useCallback(
    (value) => dispatch(["generatedUrl", value]),
    []
  );
  const setContents = useCallback((value) => dispatch(["contents", value]), []);
  const setCodeSignature = useCallback(
    (value) => dispatch(["codeSignature", value]),
    []
  );
  const setPlanId = useCallback((value) => dispatch(["planId", value]), []);
  const setPercentOff = useCallback(
    (value) => dispatch(["percentOff", value]),
    []
  );
  const setTrialDays = useCallback(
    (value) => dispatch(["trialDays", value]),
    []
  );
  const setExpiryDate = useCallback(
    (value) => dispatch(["expiryDate", value]),
    []
  );
  const setTotalUses = useCallback(
    (value) => dispatch(["totalUses", value]),
    []
  );

  const setChargeName = useCallback(
    (value) => dispatch(["chargeName", value]),
    []
  );
  const setChargePrice = useCallback(
    (value) => dispatch(["chargePrice", value]),
    []
  );
  const setChargeIsTest = useCallback(
    (value) => dispatch(["chargeIsTest", value]),
    []
  );
  const setChargeConfirmationUrl = useCallback(
    (value) => dispatch(["chargeConfirmationUrl", value]),
    []
  );

  const tokenExpiry = useMemo(() => {
    try {
      const { exp } = decodeJwt(token);
      return new Date(exp * 1000);
    } catch {
      return null;
    }
  }, [token]);

  useEffect(() => {
    if (!apps && token) {
      fetch(`${ENDPOINT_BASE}/api/apps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((r) => r.json())
        .then(({ apps: newApps }) => setApps(newApps));
    }
  }, [apps, setApps, token]);

  const hostname = useMemo(() => {
    if (/^[a-z0-9-]{3,}$/i.test(inputHostname)) {
      return `${inputHostname}.myshopify.com`;
    }
    if (/^[a-z0-9-]{3,}.myshopify.com$/i.test(inputHostname)) {
      return inputHostname;
    }
    return null;
  }, [inputHostname]);

  const hasNeededInfo =
    !!(hostname && app && reason) && tokenExpiry > +new Date();

  const appsOptions = useMemo(
    () => [
      { label: "-", value: "" },
      ...(apps
        ? apps.map(({ handle, name }) => ({
            label: name || handle,
            value: handle,
          }))
        : []),
    ],
    [apps]
  );

  const [GQLDEBUG, setGQLDEBUG] = useState(false);
  useEffect(() => {
    window.GQLDebug = () => setGQLDEBUG(true);

    return () => {
      setGQLDEBUG(false);
      delete window.GQLDebug;
    };
  }, []);

  const getProxyToken = useCallback(async () => {
    const url = new URL(`${ENDPOINT_BASE}/api/shopify_api`, window.location);
    url.searchParams.append("app", app || "");
    url.searchParams.append("shop", hostname || "");
    url.searchParams.append("reason", reason || "");
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await response.text();
    }

    const { sid } = await response.json();
    return sid;
  }, [app, hostname, reason, token]);

  const gqlFetcher = useCallback(
    async (graphQLParams) => {
      const proxyToken = await getProxyToken();

      const proxyUrl = new URL(
        `${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/graphql.json`,
        window.location
      );

      const res = await fetch(proxyUrl, {
        method: "post",
        headers: {
          Authorization: `Bearer ${proxyToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(graphQLParams),
        credentials: "same-origin",
      });

      return res.json().catch(() => res.text());
    },
    [getProxyToken]
  );

  // prepopulate fields and superficially lock them from being edited
  useEffect(() => {
    const usp = new URLSearchParams(window.location.search);
    const urlHostname = usp.get("hostname");
    const urlApp = usp.get("app");
    const urlReason = usp.get("reason");

    if (urlHostname) {
      setInputHostname(urlHostname);
    }

    if (urlApp) {
      setApp(urlApp);
    }

    if (urlReason) {
      setReason(urlReason);
    }

    if (urlHostname && urlApp && urlReason) {
      setLocked(true);
    }
  }, [setApp, setInputHostname, setLocked, setReason]);

  // perform an autologin once we have everything we need
  useEffect(() => {
    const usp = new URLSearchParams(window.location.search);
    const urlAutoLogin = usp.get("autologin");

    if (token && app && hostname && reason && locked && urlAutoLogin === "1") {
      getAppLoginUrl(token, app, hostname, reason).then(
        (url) => (window.location = url)
      );
    }
  }, [token, app, hostname, reason, locked]);

  const toastCopied = useCallback(() => {
    createToast({ content: "Copied!" });
  }, [createToast]);

  return (
    <>
      <Page
        title='What is now known as "The 𝕄 Page"'
        subtitle={
          tokenExpiry
            ? `This page expires ${new Intl.DateTimeFormat("en-CA", {
                dateStyle: "full",
                timeStyle: "long",
              }).format(tokenExpiry)}.`
            : null
        }
      >
        {token ? (
          <LegacyCard>
            <LegacyCard.Section>
              <LegacyStack vertical>
                <LegacyStack distribution="fillEvenly">
                  <Select
                    disabled={locked}
                    label="App"
                    value={app}
                    onChange={setApp}
                    options={appsOptions}
                  />
                  <TextField
                    disabled={locked}
                    label="Shop"
                    type="text"
                    value={inputHostname}
                    onChange={setInputHostname}
                  />
                </LegacyStack>
                <TextField
                  disabled={locked}
                  label="Reason"
                  type="text"
                  value={reason}
                  onChange={setReason}
                />
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack vertical>
                <LegacyStack.Item>
                  <Button
                    disabled={!hasNeededInfo}
                    onClick={async () => {
                      window.open(
                        await getAppLoginUrl(token, app, hostname, reason)
                      );
                    }}
                  >
                    Login to {app}
                  </Button>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <LegacyStack alignment="trailing">
                    <LegacyStack.Item>
                      <Button
                        disabled={!hasNeededInfo}
                        onClick={async () => {
                          const url = new URL(
                            `${ENDPOINT_BASE}/api/shopify_api`,
                            window.location
                          );
                          url.searchParams.append("app", app);
                          url.searchParams.append("shop", hostname);
                          const { sid: localSid } = await fetch(url, {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }).then((r) => r.json());
                          setSid(localSid);
                        }}
                      >
                        Get CSTK Command
                      </Button>
                    </LegacyStack.Item>
                    <LegacyStack.Item fill>
                      <TextField
                        label="Command"
                        readOnly
                        monospaced
                        value={sid ? `cstk.pl get_shop_api ${sid} x` : ""}
                        connectedRight={
                          <Button
                            onClick={() => {
                              navigator.clipboard
                                .writeText(
                                  sid ? `cstk.pl get_shop_api ${sid} x` : ""
                                )
                                .then(toastCopied);
                            }}
                            label="Copy"
                            icon={ClipboardMinor}
                          ></Button>
                        }
                      />
                    </LegacyStack.Item>
                  </LegacyStack>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section title="Discount Codes">
              <LegacyStack vertical>
                <LegacyStack.Item>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        label="Plan ID"
                        type="number"
                        value={planId}
                        onChange={setPlanId}
                      />
                      <TextField
                        label="Percentage Off"
                        type="number"
                        value={percentOff}
                        onChange={setPercentOff}
                      />
                      <TextField
                        label="Trial Days"
                        type="number"
                        value={trialDays}
                        onChange={setTrialDays}
                      />
                      <TextField
                        label="Expiry Date"
                        type="date"
                        value={expiryDate}
                        onChange={setExpiryDate}
                      />
                      <TextField
                        label="Total Uses"
                        type="number"
                        value={totalUses}
                        onChange={setTotalUses}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <LegacyStack alignment="trailing">
                    <LegacyStack.Item>
                      <Button
                        disabled={!hasNeededInfo}
                        onClick={async () => {
                          const url = new URL(
                            `${ENDPOINT_BASE}/api/generate_discount_code`,
                            window.location
                          );

                          url.searchParams.append("app", app || "");
                          url.searchParams.append("plan_id", planId || "");
                          url.searchParams.append(
                            "percent_off",
                            percentOff || ""
                          );
                          url.searchParams.append(
                            "trial_days",
                            trialDays || ""
                          );
                          url.searchParams.append(
                            "expiry_date",
                            expiryDate || ""
                          );
                          url.searchParams.append(
                            "total_uses",
                            totalUses || ""
                          );
                          url.searchParams.append("shop", hostname || "");
                          url.searchParams.append("reason", reason || "");

                          const response = await fetch(url, {
                            method: "POST",
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          });
                          if (response.ok) {
                            const { code } = await response.json();
                            setDiscountCode(code);
                          } else {
                            setDebugText(
                              `${
                                response.status
                              }\n---\n${await response.text()}`
                            );
                          }
                        }}
                      >
                        Generate Discount Code
                      </Button>
                    </LegacyStack.Item>
                    <LegacyStack.Item fill>
                      <TextField
                        readOnly
                        monospaced
                        label="Discount Code"
                        value={discountCode || ""}
                        connectedRight={
                          <Button
                            onClick={() => {
                              navigator.clipboard
                                .writeText(discountCode || "")
                                .then(toastCopied);
                            }}
                            label="Copy"
                            icon={ClipboardMinor}
                          ></Button>
                        }
                      />
                    </LegacyStack.Item>
                  </LegacyStack>
                </LegacyStack.Item>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section title="">
              <LegacyStack vertical>
                <TextField value={scopes} onChange={setScopes}></TextField>
                <LegacyStack>
                  <Button
                    onClick={async () => {
                      try {
                        const url = new URL(
                          `${ENDPOINT_BASE}/api/generate_authorize_url`,
                          window.location
                        );
                        url.searchParams.append("app", app);
                        url.searchParams.append("hostname", hostname);
                        url.searchParams.append("reason", reason);
                        url.searchParams.append("scope", scopes);

                        const response = await fetch(url, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        });
                        const { url: newGeneratedUrl } = await response.json();
                        setGeneratedUrl(newGeneratedUrl);
                      } catch {
                        setGeneratedUrl("about:blank");
                      }
                    }}
                    disabled={!scopes}
                  >
                    Generate Authorize URL
                  </Button>
                </LegacyStack>
                <TextField
                  value={generatedUrl}
                  readOnly
                  connectedRight={
                    <Button
                      onClick={() => {
                        navigator.clipboard
                          .writeText(generatedUrl)
                          .then(toastCopied);
                      }}
                      label="Copy"
                      icon={ClipboardMinor}
                    ></Button>
                  }
                ></TextField>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section title="Sign Code">
              <LegacyStack vertical>
                <TextField
                  multiline={5}
                  monospaced
                  value={contents}
                  onChange={setContents}
                ></TextField>
                <Button
                  onClick={async () => {
                    const url = new URL(
                      `${ENDPOINT_BASE}/api/get_sign_sig`,
                      window.location
                    );
                    url.searchParams.append("app", app);
                    url.searchParams.append("hostname", hostname);
                    url.searchParams.append("reason", reason);

                    const response = await fetch(url, {
                      method: "post",
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ contents }),
                    });
                    const { signature: newCodeSignature } =
                      await response.json();
                    setCodeSignature(newCodeSignature);
                  }}
                  disabled={!contents}
                >
                  Sign it
                </Button>
                <TextField value={codeSignature} readOnly></TextField>
              </LegacyStack>
            </LegacyCard.Section>

            <LegacyCard.Section title="Create Charge">
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    label="Name"
                    value={chargeName}
                    onChange={setChargeName}
                  ></TextField>
                  <TextField
                    label="Price"
                    type="currency"
                    value={chargePrice}
                    onChange={setChargePrice}
                  ></TextField>
                </FormLayout.Group>
                <Checkbox
                  label="Test Charge"
                  checked={chargeIsTest}
                  onChange={setChargeIsTest}
                ></Checkbox>
                <Button
                  onClick={async () => {
                    const sid = await getProxyToken();

                    const proxyUrl = new URL(
                      `${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/graphql.json`,
                      window.location
                    );

                    const proxyResponse = await fetch(proxyUrl, {
                      method: "post",
                      headers: {
                        Authorization: `Bearer ${sid}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                      },
                      body: JSON.stringify({
                        query: `mutation AppPurchaseOneTimeCreate($name: String!, $price: MoneyInput!, $returnUrl: URL!, $test: Boolean!) {
                            appPurchaseOneTimeCreate(name: $name, returnUrl: $returnUrl, price: $price, test: $test) {
                              userErrors {
                                field
                                message
                              }
                              appPurchaseOneTime {
                                createdAt
                                id
                              }
                              confirmationUrl
                            }
                          }`,
                        variables: {
                          name: chargeName,
                          returnUrl: "https://admin.shopify.com/",
                          price: {
                            amount: chargePrice,
                            currencyCode: "USD",
                          },
                          test: chargeIsTest,
                        },
                      }),
                    });
                    const {
                      data: {
                        appPurchaseOneTimeCreate: { confirmationUrl },
                      },
                    } = await proxyResponse.json();

                    setChargeConfirmationUrl(
                      confirmationUrl ?? "Something went wrong..."
                    );

                    setDebugText(JSON.stringify(data));
                  }}
                  disabled={!(chargeName && chargePrice && hasNeededInfo)}
                >
                  Create Charge
                </Button>
                <TextField
                  label="Charge Confirmation URL"
                  readOnly
                  value={chargeConfirmationUrl}
                  connectedRight={
                    <Button
                      onClick={() => {
                        navigator.clipboard
                          .writeText(chargeConfirmationUrl)
                          .then(toastCopied);
                      }}
                      label="Copy"
                      icon={ClipboardMinor}
                    ></Button>
                  }
                />
              </FormLayout>
            </LegacyCard.Section>

            <LegacyCard.Section title="Debug Stuff">
              {iGotThis ? (
                <LegacyStack vertical>
                  <LegacyStack>
                    <Button
                      onClick={async () => {
                        const url = new URL(
                          `${ENDPOINT_BASE}/api/whoami`,
                          window.location
                        );

                        const response = await fetch(url, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        });
                        setDebugText(await response.text());
                      }}
                    >
                      Do The Thing
                    </Button>
                    <Button
                      onClick={async () => {
                        const url = new URL(
                          `${ENDPOINT_BASE}/api/clear_cache`,
                          window.location
                        );

                        const response = await fetch(url, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        });
                        setDebugText(await response.text());
                      }}
                    >
                      Clear The Cache
                    </Button>
                    <Button
                      onClick={async () => {
                        const proxyToken = await getProxyToken();

                        const proxyUrl = new URL(
                          `${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/graphql.json`,
                          window.location
                        );
                        const proxyResponse = await fetch(proxyUrl, {
                          method: "post",
                          headers: {
                            Authorization: `Bearer ${proxyToken}`,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                          },
                          body: JSON.stringify({
                            query: `{
                          appInstallation {
                            activeSubscriptions{
                              id
                              createdAt
                              name
                              status
                              trialDays
                            }
                            accessScopes {
                              handle
                            }
                          }
                        }`,
                          }),
                        });

                        const {
                          data: {
                            appInstallation: {
                              activeSubscriptions,
                              accessScopes,
                            },
                          },
                        } = await proxyResponse.json();

                        setDebugText(
                          JSON.stringify({
                            activeSubscriptions,
                            accessScopes: accessScopes
                              .map(({ handle }) => handle)
                              .sort()
                              .join(","),
                          })
                        );
                      }}
                      disabled={!hasNeededInfo}
                    >
                      Get Scopes
                    </Button>
                    <Button
                      onClick={async () => {
                        const sid = await getProxyToken();

                        const proxyUrl = new URL(
                          `${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/${debugPath}`,
                          window.location
                        );
                        const proxyResponse = await fetch(proxyUrl, {
                          headers: {
                            Authorization: `Bearer ${sid}`,
                          },
                        });

                        setDebugText(await proxyResponse.text());
                      }}
                      disabled={!hasNeededInfo}
                    >
                      SA Fetch
                    </Button>
                    <TextField value={debugPath} onChange={setDebugPath} />
                  </LegacyStack>
                  <PrettyOrText value={debugText} />
                </LegacyStack>
              ) : (
                <Button onClick={() => setIGotThis(true)} destructive>
                  I know what I am doing
                </Button>
              )}
            </LegacyCard.Section>
          </LegacyCard>
        ) : (
          "Verifying Identity..."
        )}
        <FooterHelp>
          <Link
            onClick={() => {
              setLocked(false);
            }}
          >
            ©
          </Link>
          <Link
            onClick={() => {
              localStorage.removeItem("googleSignInToken");
              setToken(null);
            }}
          >
            2023
          </Link>{" "}
          ༼ つ ◕_◕ ༽つ
        </FooterHelp>
        <TokenMagic key="tokenMagic" setToken={setToken} />
      </Page>
      {GQLDEBUG && (
        <>
          <br />
          <br />
          <GraphiQL fetcher={gqlFetcher} />
        </>
      )}
    </>
  );
}

export default App;
